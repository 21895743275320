<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0" v-if="productGroupList">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Product Groups ({{ productGroupList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <!-- <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                            <v-btn icon :to="{ name: 'organization-create-product-group', params: { organizationId: $route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-card-text v-if="productGroupList.length === 0">
                            <p class="mb-0">No product groups.</p>
                            <!-- <v-btn style="background-color: green darken-2; color: #ffffff;" elevation="2" @click="createNewItemDialog = true">
                                Create
                            </v-btn> -->
                        </v-card-text>

                        <v-list dense v-if="productGroupList.length > 0">
                            <v-list-item v-for="(item, idx) in productGroupList" :key="idx" :to="{ name: 'organization-edit-product-group', params: { organizationId: $route.params.organizationId }, query: { id: item.id, t: Date.now() } }"> <!-- @click="openEditItemDialog(item)" -->
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.label }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-dialog v-model="createNewItemDialog" max-width="600">
                    <v-card class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text text--darken-2">Add Product Group</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                            <!-- <p>A fee of <span class="font-weight-bold">$1.25/mo</span> for this host will be added to your subscription.</p> -->
                        </v-card-text>
                        <!-- <v-alert type="error" border="left" dense v-if="!dynamicSharedDomainList" class="mx-5">
                            <template #prepend>
                                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1" fixed-width></font-awesome-icon>
                            </template>
                            <p class="ml-4 pb-0 mb-0">This feature is not currently available. Please contact customer support.</p>
                        </v-alert> -->
                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newProductGroupLabel" label="Product group label" hint="This will be displayed to customers." ref="newProductGroupLabelInput"></v-text-field>
                            <v-select v-model="newProductGroupType" :items="productTypeChoices" label="Product type"/>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        organization: null,
        productGroupList: null,

        submitFormTimestamp: null,

        createNewItemDialog: false,
        newProductGroupLabel: null,
        newProductGroupType: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            productTypeChoices: (state) => state.productTypeChoices,
        }),
        isNewItemFormComplete() {
            return typeof this.newProductGroupLabel === 'string' && this.newProductGroupLabel.length > 0 && typeof this.newProductGroupType === 'string' && this.newProductGroupType.length > 0;
        },
    },
    watch: {
        createNewItemDialog(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.newProductGroupLabel = '';
                this.newProductGroupType = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('newProductGroupLabelInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadProductGroupList() {
            try {
                this.$store.commit('loading', { loadProductGroupList: true });
                const query = {};
                const response = await this.$client.organization(this.$route.params.organizationId).productGroup.search(query);
                if (response?.list) {
                    this.productGroupList = response.list;
                }
            } catch (err) {
                console.error('loadProductGroupList failed', err);
            } finally {
                this.$store.commit('loading', { loadProductGroupList: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    label: this.newProductGroupLabel,
                    type: this.newProductGroupType,
                };
                const response = await this.$client.organization(this.$route.params.organizationId).productGroup.create(item);
                if (response?.isCreated && response.id) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    // this.productGroupList.push(response.item);
                    this.loadProductGroupList();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        this.loadProductGroupList();
    },
};
</script>
